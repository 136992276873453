<template>
  <div class="relative bg-slate-50 rounded-xl overflow-hidden h-full">
    <div class="relative rounded-xl overflow-auto p-8">
      <div class="flex flex-wrap gap-4 font-mono text-white text-sm font-bold bg-stripes-indigo rounded-lg">
        <div class="p-4 w-full rounded-lg flex h-64 items-center justify-center shadow-lg" :class="this.communalTrashAvailable?'bg-green-500':'bg-red-500'">
          {{ this.communalTrashAvailable?"Kommunális levihető! 😊":"Kommunális nem vihető le! 😭" }}</div>
        <div class="p-4 w-full rounded-lg flex h-64 items-center justify-center shadow-lg" :class="this.selectiveTrashAvailable?'bg-green-500':'bg-red-500'">
          {{ this.selectiveTrashAvailable?"Szelektív levihető! 😊":"Szelektív nem vihető le! 😭" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const now = new Date();

    return {
      communalTrashMessage: '',
      selectiveTrashMessage: '',
      communalSchedule: {
        0: { startHour: 8, endHour: 24 },
        1: { startHour: 0, endHour: 14 }, //Hétfő
        2: { startHour: 8, endHour: 24 },
        3: { startHour: 0, endHour: 14 },
        4: { startHour: 8, endHour: 24 },
        5: { startHour: 0, endHour: 24 },
        6: { startHour: 0, endHour: 14 },
      },
      selectiveSchedule: {
        0: { startHour: 0, endHour: 24 },
        1: { startHour: 0, endHour: 24 },
        2: { startHour: 0, endHour: 24 },
        3: { startHour: 0, endHour: 24 },
        4: { startHour: 0, endHour: 24 },
        5: { startHour: 0, endHour: 14 },
        6: { startHour: 8, endHour: 24 },
      },
      day: now.getDay(),
      hour: now.getHours(),
      communalTrashAvailable: false,
      selectiveTrashAvailable: false
    };
  },
  mounted() {
    this.communalTrashAvailable = this.isTrashAvailable(this.communalSchedule[this.day]);
    this.selectiveTrashAvailable = this.isTrashAvailable(this.selectiveSchedule[this.day]);
  },
  methods: {
    isTrashAvailable(schedule) {
      return this.hour >= schedule.startHour && this.hour < schedule.endHour;
    }
  },
};
</script>

<style>
/* Add your Tailwind CSS styles here if needed */
</style>
